.youth-team-card-div {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.youth-team-overview-div {
    width: 80%;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(80% / 4 - 10px), 1fr));
    grid-gap: 130px;
    grid-row-gap: 0px
}

@media (max-width: 600px) { /* Adjust the width as needed */
    .youth-team-overview-div {
      grid-template-columns: 1fr;
    }
  }


.youth-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}


