.card-span {
    padding-bottom: 5px;
}

.t-card-div {
    padding-bottom: 10px;
    /* width: 240px; */
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
}

.game-card {
    padding-left: 5px;
    padding-right: 5px
}

.card-div .ant-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-div .ant-card > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Occupies remaining vertical space */
}

.card-div .ant-card .ant-space {
    flex: 1; /* Equal distribution of Space components */
    display: flex;
    align-items: center;
}

.game-card-div {
    display: flex;
    /* flex-wrap: wrap; */
    align-items: inherit;
    justify-content: space-evenly;
    width: 80%;
}

.inner-card-div {

}
