.horizontal-div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.heading {
    color: rgba(9,21,0,0.67);
}

.container {
    display: flex;
    align-content: center;
    justify-content: center;
}

.centering {
    display: flex;
    flex-wrap: wrap;
    align-items: inherit;
    justify-content: space-evenly;
    width: 100%;
}
  
