.board-card-wrap {
    width: 300px; /* Fixed width for the card */
    padding: 15px 10px; /* Keeps the padding if needed */
    box-sizing: border-box; /* Ensures padding is included in the dimensions */
    display: flex; /* Flexbox for alignment */
    justify-content: center;
    align-items: center;
}

.board-card-div {
    width: 100%; /* Ensures this div stretches to fit the card */
    height: calc(100% - 50%); /* Adjust height to fit under the cover image */
    display: flex; /* Use flexbox for layout if needed */
    flex-direction: column; /* Stack items vertically */
    justify-content: space-around; /* Space items evenly */
    align-items: center; /* Center-align the content */
}

.board-card-wrap .ant-card {
    width: 100%; /* Stretch the card to match the parent container */
    height: 100%; /* Stretch the card to match the parent container */
}

.board-card-wrap .ant-card-cover {
    width: 100%; /* Ensure the cover spans the full width */
    height: 50%; /* Set cover height proportional to the card */
    overflow: hidden; /* Prevent overflow of the image */
}

.board-card-wrap .ant-card-cover img {
    width: 100%; /* Scale the image to fit the width */
    height: 100%; /* Scale the image to fit the height */
    object-fit: cover; /* Ensure the image scales proportionally */
}

.board-div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Flexible columns */
    gap: 20px; /* Space between cards */
    width: 90%;
    justify-content: center;
    align-items: start; /* Align cards to the top */
}

.board-picture-div-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.board-picture-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
}

.board-picture {
    width: 95%;
    height: auto; /* Maintain aspect ratio */
    max-width: 95%; /* Optional: Limit width further if needed */
    display: block; /* Ensures the image doesn't have extra spacing */
}
