.navbar {
    background-color: transparent;
    color: white;
    width: 100%;
}

.horizontal-div-navbar {
    background-color: black;
    display: flex;
    width: 100%;
}

.logo {
    height: 100px;
    width: 100px;
}

.ant-menu-item-selected {
    color: white !important;
}

.ant-menu-item-active {
    color: white !important;
    border-bottom-color: white !important;
    vertical-align: center;
}

:where(.css-dev-only-do-not-override-htwhyh).ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after, :where(.css-dev-only-do-not-override-htwhyh).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item-selected::after, :where(.css-dev-only-do-not-override-htwhyh).ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected::after, :where(.css-dev-only-do-not-override-htwhyh).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected::after {

    border-bottom-width: 2px;
    border-bottom-color: white;

}

.ant-menu-item-icon {
    height: 100% !important;
}

.navbar-drawer {
    color: white;
    padding-left: 14px;
    padding-top: 14px;
    font-size: 24px;
    padding-bottom: 14px;
}

@media (min-width: 651px) {
    .mobile {
        display: none;
    }

    .ant-menu-item-selected {
        background-color: transparent !important;
    }
}

@media (max-width: 650px) {
    .desktop {
        display: none;
    }

    .ant-menu-item-selected {
        background-color: gray !important;
    }
}
