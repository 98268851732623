.api-div {
    width: 80%;
    text-align: left;
}

body {
    text-align: left;
}

.api-img {
    width: 100%;
    height: 100%;
}

a {
    color: blue;
}

a:hover {
    color: lightskyblue;
}

.container {
    max-width: 80%;
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px; /* Optional: Add padding for spacing */
}

header, main, footer {
    padding: 10px; /* Add padding to your header, main, and footer if desired */
}
