.vtt_footer {
    background-color: black;
    color: white;
    margin-top: 50px;
}

a {
    color: white;
    text-decoration: none;
}

a:hover {
    color:white; 
    text-decoration:none; 
    cursor:pointer;  
}

.half-div-wrap {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    height: 100%;
}

.half-div {
    width: 50%;
    /*background-color: blue;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-div {
    padding-top: 20px;
}
