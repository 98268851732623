.team-card-div {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.team-overview-div {
    justify-content: center;
}

.player-div {
    padding: 30px;

}

.player-div-2 {
    padding: 30px;
}

.page-wrap {
    width: 100%;
}

.table {
    padding: 15px;
}

.half-div-wrap {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
}

.half-div {
    width: 50%;
    /*background-color: blue;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.half-div-debug {
    width: 50%;
    /*background-color: yellow;*/
    display: flex;
    justify-content: center;
    align-items: self-start;
    flex-direction: column;
}

.button-div {
    width: 100%;
}

.contact-div {
    align-items: self-start;
}

.training-div {
    /*background-color: yellow;*/
}

.contact-training-div {

}

@media (max-width: 450px) {
    .half-div-wrap {
        width: 100%;
    }

    .table {
        padding: 0;
    }
}

@media (max-width: 900px) {
    .contact-training-div {
        flex-direction: column;
    }
}

@media (max-width: 1250px) {
    .half-div {
        width: 100%;
    }

    .table {
        width: 100%;
    }

    .half-div-debug {
        display: none;
    }
}

.team-image {
    padding: 15px;
    border-radius: 50px;
}
