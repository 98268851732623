.sponsor-div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}

.width-con {
    width: 100%;
}

.sponsor-wrap-div {
    width: 90%;
}
