.card {
    padding-top: 1px;
}

.card-div {
    justify-content: space-around;
    width: 75%;
}

.parent-div {
    display: flex;
    align-content: center;
    justify-content: center;
}

.child-div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}

.picture {
    height: 80%;
    width: 80%;
    object-fit: fill;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.trooper {
    height: 20%;
    width: 20%;
}

.clickable-link {
    display: contents;
}


.bottom-padding {
    padding-bottom: 30px;
}

.picture-div-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.picture-div {
    width: 90%;
}
